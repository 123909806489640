.block {
  display: flex;
  align-items: flex-start;
  background: #d0f8fb;
  padding: 1rem;
  /* margin: 0 0 1rem 0; */
}
.block > p {
  width: 75px;
  margin: 0 1rem 0 0;
}
.block > div {
  flex: 1;
}
.block h2 {
  font-weight: 500;
  margin: 0 0 0.5rem 0;
}
