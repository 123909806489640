.services {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header h1 {
    text-align: center;
  }

  .light-text {
    color: #86868b;
  }

  .flex-row {
    display: flex;
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .column {
    flex: 1;
    padding: 16px;
    padding-bottom: 80px;
    border: 2px solid #ddd;
    cursor: pointer;
    margin: 15px;
    border-radius: 12px;
    position: relative;
  }

  .column:hover {
    border: 2px solid #0071e3;
  }

  .selected {
    border: 2px solid #0071e3;
  }

  @media screen and (max-width: 850px) {
    .flex-row {
      flex-direction: column !important;
    }
  }

  .help-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 14px 15px;
    text-align: left;
    cursor: pointer;
  }

  .help-message {
    background: #f5f5f7;
    padding: 10px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    max-width: 290px;
  }

  .help-message p:first-of-type {
    margin: 0;
  }

  .img-container {
    display: inline-block;
    height: 200px;
    background: #f2f2f2f2;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }
  .img-container img {
    max-width: 280px;
    max-height: 200px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .help-video-container {
    display: flex;
    justify-content: center;
    margin: 15px;
  }

  .pointer {
    cursor: pointer;
  }

  .aap-base__icon {
    top: auto;
    right: auto;
    transform: rotate(45deg);
    height: 50px;
    width: 50px;
    background: gray;
  }

  .help-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
  }
}
