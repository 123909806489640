.mortgage-calculator {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

label {
  font-weight: bold;
  padding-bottom: 10px;
}

input {
  border: 1px solid #ccc;
  padding: 15px;
}

span {
  margin-top: 5px;
  font-weight: bold;
  text-align: center;
}
