.cta {
  padding: 20px;

  .center,
  .sub-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bouncing-container {
    display: flex;
    justify-content: center;

    .bouncing-span {
      display: inline-block;
      font-size: 24px;
      animation: bounce 2s infinite;
      margin: 0 10px;
      padding: 5px;
      border-radius: 6px;
      font-weight: 900;
    }

    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px); /* Adjust the bounce height as needed */
      }
    }
  }
  .subscribe-section {
    display: flex;
    gap: 20px;
    flex-direction: column;

    .message-box {
      border: 1px solid black;
      border-radius: 6px;
      padding: 28px;
      font-size: 18px;
    }
  }

  .contact-details {
    display: flex;
    gap: 20px;

    input {
      padding: 28px;
      font-size: 17.5px;
      width: 100%;
    }
  }

  .send-message-btn {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
}
