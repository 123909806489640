/* Footer Styles */
.footer {
  border-top: 5px solid #f2f2f2;
  // background-color: #f2f2f2;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust as needed */
    margin: 0 auto;
  }

  .footer-column {
    flex: 1;
    padding: 0 20px;
    margin-bottom: 20px;

    h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 8px;
    }
  }

  .footer-bottom {
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
  }

  .footer-bottom p {
    margin: 0;
    font-weight: 900;
  }

  @media screen and (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }

    .footer-column {
      flex: none;
      text-align: center;
      margin-bottom: 20px;
    }
    .footer-bottom {
      text-align: center;
      padding: 20px 0;
      font-size: 14px;
    }

    .footer-bottom p {
      margin-bottom: 0;
      font-weight: 900;
    }
  }
}
