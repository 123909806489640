.why-us {
  padding: 20px;
  flex: 1;
  /* max-width: 600px; Limit the width for responsive layout */

  .reasons-list {
    list-style-type: none;
    padding-left: 20px;
  }

  .reasons-list li {
    margin-bottom: 8px;
    line-height: 1.7;
  }

  .row {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
  }

  .why-column {
    padding: 16px;
    // display: flex;
    // align-items: center;
    min-height: 590px;
    background-color: #f3f4f6;

    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .why-column .why-header {
    font-size: 100px;
  }

  .why-column h2 {
    line-height: 50px;
  }

  .benefit-column {
    border-radius: 8px;
    background-color: #f3f4f6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    // display: flex;
    // align-items: center;
    min-height: 590px;
  }

  .benefit-column h2 {
    line-height: 50px;
  }

  ul li::before {
    content: '◆'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 2em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    font-size: 10px;
  }

  .text-center {
    text-align: center;
  }

  @media screen and (max-width: 868px) {
    .row {
      grid-template-columns: 1fr; /* Switch to single column layout */
      height: auto; /* Reset height for mobile */
    }
  }
}
