/* .nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  background-color: black;
  color: #fff;
  position: sticky;
}

.nav .links {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

.nav .links > p {
  cursor: pointer;
}

.nav .links > p:last-child {
  margin-right: 20px;
} */

/* StickyNavbar.css */
.navbar {
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  padding: 1rem;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.links {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-right: 90px;
}
