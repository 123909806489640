.badge-new {
  padding: 5px;
  color: rgb(191, 72, 0);
  /* color: #fff; */
  border-radius: 6px;
  font-size: 12px;
}

.buy-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  /* background-color: #3498db; */
  color: white;
  text-align: center;
}

.buy-btn a {
  width: 100%;
  padding: 8px;
  color: black;
  text-decoration: none;
}
