body {
  margin: 0;
  font-family: Anek Malayalam, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  transition-duration: 0.4s;
  border-radius: 6px;
  border: 1px solid;
  padding: 10px;
  width: 150px;
  font-weight: 900;
}

button:hover {
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

input {
  padding: 10px;
  border-radius: 6px;
  width: 250px;
}

textarea {
  padding: 10px;
  border-radius: 6px;
  min-width: 250px;
  font-family: Anek Malayalam, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

.badge {
  display: inline-block;
  padding: 5px;
  border-radius: 6px;
  font-weight: 900;
}
