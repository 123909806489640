.content-section {
  p {
    margin-top: 0;
  }

  .tag-line {
    font-weight: bold;
    letter-spacing: 3px;
  }

  .main-section {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    max-width: 1200px;
  }
  .main-section .column.left {
    width: 230px;
    flex: 0 0 230px;
  }

  .main-section .column.center {
    border-left: 1px solid #eee;
  }

  .row {
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .ru-column {
    transition-duration: 0.4s;
    padding: 16px;
    display: flex;
    align-items: center;
    height: 500px;
  }

  .ru-column .ru-header {
    font-size: 100px;
  }

  .click-me {
    margin-top: 60px;
    border-bottom: 1px solid;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
  }

  .black-column {
    background-color: black;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
  }

  .black-column .text-center div {
    display: flex;
    gap: 10px;
  }

  .text-center {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .row {
      grid-template-columns: 1fr; /* Switch to single column layout */
      height: auto; /* Reset height for mobile */
    }

    .black-column {
      height: 200px;
    }
    .ru-column .ru-header {
      font-size: 75px;
    }
  }

  .content {
    padding: 20px;

    h1 {
      line-height: 50px;
    }
    p {
      line-height: 35px;
    }
  }

  .logos-container {
    overflow: auto;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10%;
      margin: 5% 0;
    }
    img {
      width: 200px;
    }
  }
}
